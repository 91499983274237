import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, throwError, timeout } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { customDate, WorkListAction } from '../../interface/common.interface';
import { DataService } from '../data/data.service';
import { ClientDetail } from '../../../common/interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private dataService: DataService) {}

  public getStreamTypeDetails(streamID: string | undefined): Observable<{results: ClientDetail[]}> {
    return this.http.get<{results: ClientDetail[]}>(
      `${environment.BASE_URL}stream/type/details/${streamID}`
    );
  }

  public getOverallProgress(streamID: string | undefined): Observable<any> {
    // overall/progress/?stream_type_id=663b580a045d45730403a289&client_id=luna&type=open
    return this.http.get(
      `${environment.BASE_URL}overall/progress/?stream_type_id=${streamID}&type=open`
    );
  }

  public getWorklistProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode !== 'all') {
      params = params.set('type', statusMode);
    }

    const API = `${environment.BASE_URL}worklist/`;
    return this.http.get(API, { params });
  }

  public searchInfoLiveConsole(type: string, typeId: string, status: string,  searchId: string): Observable<any> {
    let params = new HttpParams()
      .set('type', type)
      .set('status', status)
      .set('container_id', searchId)
    const API = `${environment.BASE_URL_PRODUCT}containers/remaining/${typeId}`;
    return this.http.get(API, { params });
  }

  public getOrderProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode !== 'all') {
      params = params.set('type', statusMode);
    }

    const API = `${environment.BASE_URL_ORDER}orders/`;
    return this.http.get(API, { params });
  }

  public getContainerProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('container_type', 'carton')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode !== 'all') {
      params = params.set('type', statusMode);
    }

    const API = `${environment.BASE_URL_PRODUCT}containers/`;
    return this.http.get(API, { params });
  }

  public getWaveMetaData(id: string, type: string): Observable<any> {
    const API = `${environment.BASE_URL}${type}/${id}`;
    return this.http.get(API);
  }

  public getOverallOrderProgress(
    streamID: string | undefined
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamID}/orders/?overall_progress=True`
    );
  }

  public getOverallCartonProgress(
    streamID: string | undefined
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamID}/containers/?overall_progress=True`
    );
  }

  public getAllStream(): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/type`
    );
  }

  public getAllStreamStages(id: string): Observable<any> {
    return this.http.get(`${environment.BASE_URL}stage/?category_id=${id}`);
  }

  public getAllViews(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}view/`);
  }

  public getLiveConsoleData(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    let params = new HttpParams()
    const action = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions?page_number=${page}&page_size=${count}`,
       { params}
    );
    const event = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events?page_number=${page}&page_size=${count}`,
      { params}
    );
    return forkJoin([action, event]);
  }

  public getAllErrorsWarning(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    let params = new HttpParams()
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events?page_number=${page}&page_size=${count}`,
      { params }
    );
  }

  public getAllAction(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    let params = new HttpParams()
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions?page_number=${page}&page_size=${count}`,
      { params }
    );
  }

  public getLiveConsolePageData(
    streamId: string,
    type: string,
    typeID: string
  ): Observable<any> {
    let params = new HttpParams()
    let callType = '';
    if (type === 'container detail') callType = 'containers';
    if (type === 'order detail') callType = 'order';
    if (type === 'worklist detail') callType = 'worklist';
    const action = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/${callType}/${typeID}/actions`,
      { params }
    );
    const event = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/${callType}/${typeID}/events`,
      { params }
    );
    return forkJoin([action, event]);
  }

  public runPrecogBot(worklistId: number): Observable<any> {
    return this.http.post(
      `${environment.BASE_URL_PRECOG}engine/bot/run/?client_id=674d71a4902ebb292c967406`,
      { 
        label: worklistId,
        bot_id: '672ce8c08b25b8d751268e88',
        status: 'live',
        params: {
          wave_number: {
            value: worklistId, 
            type: "string",
            name: "Wave Number",
            key: "wave_number"
          }
        }
      }
    ).pipe(
      timeout(2 * 60 * 1000), // Set timeout to 30 seconds
      catchError((error: HttpErrorResponse) => {
        console.error('Error occurred:', error);
        return throwError(() => new Error('Request timed out or failed'));
      })
    );
  }

  public getLiveConsoleAction(streamId: string | undefined): Observable<any> {
    let params = new HttpParams()
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions`,
      { params }
    );
  }
  public getLiveConsoleEvents(streamId: string | undefined): Observable<any> {
    let params = new HttpParams()
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events`,
      { params }
    );
  }

  public getContainerIdProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/containers/${externalId}/?page_number=${page}&page_size=${count}`
    );
  }

  public getOrderIDProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    let API = `${environment.BASE_URL}stream/${streamId}/orders/${externalId}/?page_number=${page}&page_size=${count}`;
    return this.http.get(API);
  }

  //search api for order deatil page
  public searchOrderData(
    streamId: string,
    externalId: string,
    type?: string,
    searchId?: string
  ): Observable<any> {
    let API = `${environment.BASE_URL}stream/${streamId}/orders/${externalId}/?type=${type}&id=${searchId}`;
    return this.http.get(API);
  }

  public getWorklistIdProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/worklist/${externalId}/?page_number=${page}&page_size=${count}`
    );
  }

  public searchProgressDetails(search_id: string): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/stream/search_api/${search_id}`
    );
  }

  public getDivertedLaneData(
    streamId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}streams/lanes/`
    );
  }

  public getDivertedLaneDetail(
    streamId: string,
    lane: string
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}streams/lanes/${lane}`
    );
  }

  public getLaneMonitorLiveConsole(workList: WorkListAction): Observable<any> {
    const actions = this.http.post(
      `${environment.BASE_URL}stream/containers/actions/`,
      workList
    );
    const events = this.http.post(
      `${environment.BASE_URL}stream/containers/events/`,
      workList
    );
    return forkJoin([actions, events]);
  }

  // public getDateRange(): Observable<any> {
  //   return this.http.get(environment.BASE_URL + 'streams/reports/daterange/');
  // }

  public getDivertedPallentized(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL + 'streams/reports/cartons/palletized/download/?';
    } else {
      API = environment.BASE_URL + 'streams/reports/cartons/palletized/?';
    }
    const date = '2024-02-12 17:37:05.423';
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `&group_by=${groupBy}&is_all_time=true`;
      } else if (isLastWeek) {
        API = API + `&is_last_week=True&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `&is_last_month=True&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `&is_last_day=True&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getDivertedPallentizedAnomaly(
    time: string,
    pageSize: number,
    pageNumber: number,
    reason: string
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/cartons/palletized/anomaly/?page_size=${pageSize}&page=${pageNumber}&${time}=true&reason=${reason}`;
    return this.http.get(API);
  }

  public getTaskHealth(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/task_health/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL +
        'streams/reports/task_health/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true&group_by=${groupBy}`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `is_last_day=true&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getTaskHealthAnomaly(
    time: string,
    pageSize: number,
    pageNumber: number
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/task_health/anomaly/?page_size=${pageSize}&page=${pageNumber}&${time}=true`;
    return this.http.get(API);
  }

  // public getTaskHealthAnomalyMock(timePersiod: string, pageSize: number, pageNumber: number): Observable<any> {
  //   const API = `${environment.BASE_URL}streams/reports/task_health/anomaly/?page_size=${pageSize}&page_number=${pageNumber}&${timePersiod}=true`;
  //   return this.http.get(API)
  // }

  public getCornerstoneRecirculation(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/recirc/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL + 'streams/reports/recirc/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true&group_by=${groupBy}`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `is_last_day=true&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getCornerstoneRecirculationAnomaly(
    timePersiod: string,
    pageSize: number,
    pageNumber: number
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/recirc/anomaly/?page_size=${pageSize}&page=${pageNumber}&${timePersiod}=true`;
    return this.http.get(API);
  }

  public getDivertReconciliation(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/divertreconciliation/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL +
        'streams/reports/divertreconciliation/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true`;
      } else if (isLastDay) {
        API = API + `is_last_day=true`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public searchProgressById(id: string) {
    const API = `${environment.BASE_URL}stream/search/${id}`;
    return this.http.get(API);
  }

  public getLiveConsoleInfoData(
    id: string,
    status: string | undefined,
    type: string | undefined,
    page: number,
    pageSize: number,
    streamID: string | undefined,
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page)
      .set('page_size', pageSize);

    const API = this.http.get(`${environment.BASE_URL}streams/${streamID}/containers/remaining/${id}?type=${type}`, { params });
    const API2 = this.http.get(`${environment.BASE_URL}streams/${streamID}/${type}/${id}`, { params });

    return forkJoin([API, API2]);
  }

  public getDivertLaneInfo(lane: string | undefined, position: string | undefined, page: number, page_size: number): Observable<any> {
    let API = `${environment.BASE_URL_PRODUCT}containers/remaining/lane/${lane}/position/${position}/?page=${page}&page_size=${page_size}`;
    return this.http.get(API);
  }

  public getPicketCount(worklistID: string, type: string): Observable<any> {
    let API = `${environment.BASE_URL}streams/${type}/${worklistID}/picktickets/count/`;
    return this.http.get(API);
  }

  public getContainerInfoData(id: string): Observable<any> {
    return this.http.get(`${environment.BASE_URL_PRODUCT}containers/${id}`);
  }

  public getProductInfo(id: string): Observable<any> {
    return this.http.get(`${environment.BASE_URL_PRODUCT}products/?unspc_id=${id}`);
  }

  public getTenants() {
    return this.http.get(
      `${environment.keycloak.authority}/admin/realms/${environment.keycloak.realm}/groups/${environment.keycloak.groupId}/children/`
    );
  }

  public getLaneLoadedData(): Observable<any> {
    //https://api-product-dev.predigle.com/containers/stream/lanes?client_id=luna&type=loaded&page_size=20&page=1
    return this.http.get(
      `${environment.BASE_URL_PRODUCT}containers/stream/lanes?&type=loaded`
    )
  }

}
